.container {
  width: 80%;
}
.homePage-slide-container {
  width: 70%;
  margin-top: 20px;
  box-shadow: rgba(149, 157, 165, 1) 0px 15px 10px;
}
.service-content {
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  gap: 10px;
}
.service-content > div {
  gap: 35px;
  border: 1px solid black;
  border-radius: 16px;
  padding: 10px;
}
.communication-icon {
  max-width: 50px;
  object-fit: contain;
}
.homepage-communition {
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-top: 10px;
}
.communition-button {
  display: flex;
  justify-content: center;
  background-image: url("../../assets/sliderBg.jpg");
  border-radius: 16px;
  color: white;
  padding: 10px;
  gap: 15px;
}
.communition-logo {
  width: 20px;
  object-fit: contain;
}
@media screen and (max-width: 915px) {
  .homePage-slide-container {
    width: 95%;
  }
}
@media screen and (max-width: 700px) {
  .service-content {
    grid-template-columns: 1fr 1fr;
  }
  .homepage-communition {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 475px) {
  .service-content {
    grid-template-columns: 1fr;
  }
}
