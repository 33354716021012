.car-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  padding: 10px;
}
.car-img-content {
  width: 300px;
  height: 100%;
}
.car-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
  border-radius: 16px;
  padding: 10px;
  background-color: rgb(45, 45, 45, 0.2);
  position: relative;
  box-shadow: rgba(149, 157, 165, 1) 0px 3px 3px;
  transition: all 0.2s ease-in-out;
  user-select: none;
}
.car-box:hover {
  transform: scale(1.02);
}
.car-title {
  margin-bottom: 20px;
}
.car-background {
  opacity: 0.5;
  z-index: -5;
  width: 100%;
}
.car-bg-container {
  position: absolute;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -11;
  height: 100%;
}
.slide-container {
  padding: 0;
}
.slide-img {
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .car-img-content {
    width: 200px;
  }
}
@media screen and (max-width: 700px) {
  .car-content {
    grid-template-columns: 1fr;
  }
}
