.reservation-form-area {
  background-image: url("../../assets/sliderBg.jpg");
  padding: 30px;
  margin-top: 10px;
  box-shadow: rgb(149 157 165) 0px 15px 10px;
  border-radius: 16px;
  width: 35%;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.reservation-form-left-content {
  gap: 10px;
}
#startDate,
#endDate {
  border-radius: 0.375rem;
  padding: 5px;
  width: 100%;
}
.date-input-div {
  gap: 10px;
}
label {
  color: gold;
}
.reservation-submit-button {
  background-color: gold;
  padding: 10px;
  border-radius: 12px;
  cursor: pointer;
}
.phone-number-input {
  padding: 10px;
  border-radius: 0.375rem;
}
@media screen and (max-width: 915px) {
  .reservation-form-area {
    width: 70%;
  }
}
@media screen and (max-width: 450px) {
  .date-input-div {
    flex-direction: column;
  }
}
