a {
  text-decoration: none;
  color: inherit;
}
li {
  border-bottom: 1px solid gold;
  padding: 15px;
}
.contact-container {
  grid-template-columns: 1fr 2fr;
  gap: 15px;
}
iframe {
  width: 100%;
  height: 100%;
  min-width: 300px;
}
.maps {
  padding: 10px;
}
@media screen and (max-width: 900px) {
  .contact-container {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 450px) {
  ul {
    padding-inline-start: 3px;
  }
  iframe {
    min-width: 200px;
  }
}
