@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
* {
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  box-sizing: border-box;
}
#root {
  position: relative;
  min-height: 100vh;
}
.background {
  opacity: 0.05;
  z-index: -5;
  width: 40%;
}
.bg-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -11;
}
.d-flex {
  display: flex;
}
.d-grid {
  display: grid;
}
.d-none {
  display: none;
}
.flex-d-col {
  flex-direction: column;
}
.flex-d-row {
  flex-direction: row;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-s-between {
  justify-content: space-between;
}
.justify-s-around {
  justify-content: space-around;
}
.width-70 {
  width: 70%;
}
.full-w {
  width: 100%;
}
.semi-w {
  width: 50%;
}
.page-title {
  background-image: url("./assets/sliderBg.jpg");
  line-height: 50px;
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid gold;
  padding: 10px;
  color: gold;
  user-select: none;
}
.page-content {
  border: 2px solid gold;
  margin-top: 10px;
  border-radius: 16px;
}
.icon {
  max-width: 25px;
  margin-right: 10px;
  object-fit: contain;
}
ul {
  list-style-type: none;
}
li {
  display: flex;
}
.home-page,
.araclar,
.iletisim {
  padding-bottom: 170px;
}
footer {
  max-height: 160px;
}
@media screen and (max-width: 915px) {
  .page-content {
    width: 95%;
  }
  .background {
    width: 60%;
  }
}
@media screen and (max-width: 450px) {
  .background {
    width: 80%;
  }
  .home-page,
  .araclar,
  .iletisim {
    padding-bottom: 255px;
  }
  footer {
    max-height: 245px;
  }
}
