header {
  min-height: 100px;
  border-bottom: 1px solid gold;
  background-image: url("../../assets/header-background.jpg");
  background-size: contain;
  background-repeat: repeat;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 1) 0px 15px 10px;
  user-select: none;
}
.header-content {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.header-content-button {
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}
.header-content-button > a {
  text-decoration: none;
  color: gold;
  padding-bottom: 5px;
  transition: transform 100ms ease-in-out;
}
.header-content-button > a::after {
  display: block;
  content: "";
  border-bottom: solid 5px gold;
  border-radius: 30%;
  transform: scaleX(0);
  transition: transform 100ms ease-in-out;
}
.header-content-button > a:hover::after {
  transform: scaleX(0.2);
}
.header-content-button > a:hover {
  transform: scale(1.1);
}
.homeImage > a > img {
  max-width: 90px;
}
.mobile-header > img {
  width: 30px;
}
.outside-mobilemenu {
  width: 0px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
}
.mobile-sidemenu {
  background-image: url("../../assets/sliderBg.jpg");
  color: gold;
  position: fixed;
  right: 0;
  top: 0;
  width: 0px;
  height: 0px;
  z-index: 3;
  transition: width ease 0.6s;
}
.mobile-sidemenu > ul > li > a {
  width: 100%;
}
#mobileMenu:checked ~ .mobile-sidemenu {
  width: 70%;
  height: 100vh;
}
#mobileMenu:checked ~ .outside-mobilemenu {
  width: 70%;
  height: 100vh;
}
@media screen and (max-width: 800px) {
  .desktop-header {
    display: none;
  }
  .mobile-header {
    display: flex;
  }
  .header-content {
    width: 95%;
  }
}
