.footer-content {
  background-image: url("../../assets/sliderBg.jpg");
  margin-top: 10px;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
}
.footer-logo > img {
  max-width: 90px;
}
.footer-section {
  grid-template-columns: 1fr 2fr;
}
.footer-nav {
  color: gold;
  display: block;
}
.footer-nav > ul > li {
  padding: 5px;
  border: none;
}
.footer-social {
  gap: 5px;
}
.footer-desc {
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 450px) {
  .footer-logo > img {
    max-width: 80px;
  }
  .footer-logo {
    justify-content: center;
  }
  .footer-section {
    grid-template-columns: 1fr;
    gap: 20px;
    width: 95%;
  }
  .footer-desc {
    grid-template-columns: 1fr 1fr;
  }
}
