@import "react-slideshow-image/dist/styles.css";

.each-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-container {
  padding: 20px;
}
.slide-img {
  object-fit: contain;
  width: 90%;
  max-height: 500px;
}
